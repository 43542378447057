<template>
  <div>
    <a-table
      row-key="id"
      :columns="columns"
      :data-source="value"
      :loading="isFetching"
      :pagination="pagination"
      :custom-row="rowClicked"
      @change="handleTableChange"
    >
      <template #name="{ record }">
        <div class="name-wrap">
          <div v-show="!record.readAt" class="circle-danger" />
          <div class="has-text-weight-bold">
            {{ getTitle(record) }}
          </div>
          <div>{{ getDescription(record) }}</div>
        </div>
      </template>
      <template #property="{ record }">
        {{ record.property ? record.property.name : '' }}
      </template>
      <template #room="{ record }">
        {{ record.room ? record.room.name : '' }}
      </template>
      <template #date="{ record }">
        {{ $filters.date(record.createdAt) }}
      </template>
      <!-- <template #detail="{ record }">
        <a @click.prevent="showDetail(record)">{{ $t('Detail').toLowerCase() }}</a>
      </template> -->
      <template #action="{ record }">
        <Delete :record="record" @delete="$emit('delete')" />
      </template>
    </a-table>
  </div>
</template>

<i18n src="../locales.json"></i18n>

<script>
import list from '@/views/alerts/mixins/list';
import Delete from './DeleteAlert';

export default {
  name: 'AlertsList',
  components: {
    Delete,
  },
  mixins: [list],
  emits: ['delete'],
  data() {
    return {
      columns: [
        {
          title: this.$t('Alert'),
          dataIndex: 'name',
          width: 500,
          slots: { customRender: 'name' },
        },
        {
          title: this.$t('Property'),
          dataIndex: 'property',
          slots: { customRender: 'property' },
        },
        {
          title: this.$t('Room Name'),
          dataIndex: 'room',
          slots: { customRender: 'room' },
        },
        {
          title: this.$t('Timestamp'),
          dataIndex: 'date',
          slots: { customRender: 'date' },
        },
        // {
        //   title: this.$t('Detail'),
        //   dataIndex: 'detail',
        //   key: 'detail',
        //   slots: { customRender: 'detail' }
        // },
        {
          title: ' ',
          key: 'action',
          width: '100px',
          slots: { customRender: 'action' },
        },
      ],
    };
  },
  methods: {
    rowClicked(record) {
      return {
        onClick: () => {
          this.showDetail(record);
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.name-wrap {
  position: relative;
  padding-left: 25px;

  .circle-danger {
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -5px;
  }
}
</style>
