{
  "en": {
    "new-lost-and-found-title": "Lost Product for \"{property}\"",
    "new-lost-and-found-desc": "Lost Product for \"{property}\" \"{room}\". \"{name}\".",
    "new-out-of-order-title": "Out of Order for \"{property}\"",
    "new-out-of-order-desc": "Out of Order for \"{property}\" \"{room}\". \"{name}\".",
    "new-incident-title": "Incident for \"{property}\"",
    "new-incident-desc": "Incident for \"{property}\". \"{name}\".",
    "rejected-expense-title": "Expense was rejected",
    "rejected-expense-desc": "The expense request was rejected. Please check the comment written inside the rejected report.",
    "low-stock-title": "Below Stock margin for \"{category}\"",
    "low-stock-desc": "Below Stock margin for \"{category}\", \"{name}\" for \"{property}\" \"{stock}\". Stock no: {quantity}, alert margin: {alertMargin}",
    "cancel-reservation-title": "Reservation Canceled",
    "cancel-reservation-desc": "Reservation was canceled.",
    "taken-room-assignment-title": "Room Assignment Taken",
    "taken-room-assignment-desc": "Room assignment was taken by other housekeeping company.",
    "room-number-assignment-finished-title": "Room number was assigned",
    "room-number-assignment-finished-desc": "Room number \"{roomNumber}\" was assigned to \"{reservationNumber}\" on check in date \"{checkin}\".",
    "room-number-assignment-not-possible-title": "Room number assignment is not done",
    "room-number-assignment-not-possible-desc": "Room number assignment was not done for \"{reservationNumber}\".  Please manually assign the room number.",
    "room-number-email-not-sent-title": "Room number email was not sent to the guest",
    "room-number-email-not-sent-desc": "Room number email was not sent for \"{reservationNumber}\". Please check the email address and resend, or contact guest offline.",
    "topline-uploaded-title": "Top Line Check is necessary",
    "topline-uploaded-desc": "Uploaded reservation has been cross referenced by SRS. Please adjust any necessary cost and change the status to \"Checked\".",
    "topline-has-not-changed-title": "Top Line Check is necessary",
    "topline-has-not-changed-desc": "Uploaded reservation has been cross referenced by SRS. Please adjust any necessary cost and change the status to \"Checked\".",
    "topline-finished-title": "Top Line Check is finished",
    "topline-finished-desc": "Top Line Check was done by Sales Promotion and Operation team.",
    "new-room-assignment-title": "Room Assignment Request",
    "new-room-assignment-desc": "Room assignment for new reservation.",
    "approved-room-assignment-title": "Room Assignment Request",
    "approved-room-assignment-desc": "Room assignment for new reservation.",
    "double-booking-title": "Double Booking Detected",
    "double-booking-desc": "Double-booking was detected",
    "scheduled-email-not-sent-title": "Scheduled email was not sent to the guest",
    "scheduled-email-not-sent-desc": "Scheduled email was not sent for \"{reservationNumber}\" Titled \"{emailSubject}\". Please check the email address and resend, or contact guest offline."
  },
  "ja": {
    "new-lost-and-found-title": "\"{property}\"に忘れ物",
    "new-lost-and-found-desc": "\"{property}\" \"{room}\"に忘れ物。\"{name}\".",
    "new-out-of-order-title": "\"{property}\"が使用不可",
    "new-out-of-order-desc": "\"{property}\" \"{room}\"で使用不可。\"{name}\".",
    "new-incident-title": "\"{property}\"に重大な設備不備",
    "new-incident-desc": "\"{property}\"に重大な設備不備。\"{name}\".",
    "rejected-expense-title": "経費が拒否されました",
    "rejected-expense-desc": "経費申請が拒否されました。拒否レポートに記載してあるコメントをお読みください。",
    "low-stock-title": "\"{category}\"の在庫数が閾値を下回りました。",
    "low-stock-desc": "\"{property}\" \"{stock}\"の \"{category}\" \"{name}\"が閾値を下回りました。在庫数: {quantity}, 閾値: {alertMargin}",
    "cancel-reservation-title": "この予約はキャンセルになりました。",
    "cancel-reservation-desc": "この予約はキャンセルになりました。",
    "taken-room-assignment-title": "申し込み終了",
    "taken-room-assignment-desc": "清掃依頼の申し込みが終了しました。",
    "room-number-assignment-finished-title": "Room number was assigned",
    "room-number-assignment-finished-desc": "Room number \"{roomNumber}\" was assigned to \"{reservationNumber}\" on check in date \"{checkin}\".",
    "room-number-assignment-not-possible-title": "Room number assignment is not done",
    "room-number-assignment-not-possible-desc": "Room number assignment was not done for \"{reservationNumber}\".  Please manually assign the room number.",
    "room-number-email-not-sent-title": "Room number email was not sent to the guest",
    "room-number-email-not-sent-desc": "Room number email was not sent for \"{reservationNumber}\". Please check the email address and resend, or contact guest offline.",
    "topline-uploaded-title": "Top Line Check is necessary",
    "topline-uploaded-desc": "Uploaded reservation has been cross referenced by SRS. Please adjust any necessary cost and change the status to \"Checked\".",
    "topline-has-not-changed-title": "Top Line Check is necessary",
    "topline-has-not-changed-desc": "Uploaded reservation has been cross referenced by SRS. Please adjust any necessary cost and change the status to \"Checked\".",
    "topline-finished-title": "Top Line Check is finished",
    "topline-finished-desc": "Top Line Check was done by Sales Promotion and Operation team.",
    "new-room-assignment-title": "予約に関するお知らせ",
    "new-room-assignment-desc": "新しい予約が入りました。",
    "approved-room-assignment-title": " 清掃に関するお知らせ",
    "approved-room-assignment-desc": "新しい清掃が割り当てられました。",
    "double-booking-title": "ダブルブッキングが発生しました",
    "double-booking-desc": "ダブルブッキングが発生しました。",
    "scheduled-email-not-sent-title": "Scheduled email was not sent to the guest",
    "scheduled-email-not-sent-desc": "Scheduled email was not sent for \"{reservationNumber}\" Titled \"{emailSubject}\". Please check the email address and resend, or contact guest offline."
  }
}
